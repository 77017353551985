import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'

const StyledImg = styled.img`
    width: 300px;
    height: 300px;
    align-self: center;
    align-items: center;
    margin: 15px;
`

const Container = styled.div`
    display: flex;
    flex-flow: column;
`

const HeroImage = styled.div`
    width: 100%;
    height: 50px;
    background-color: #1fc8db;
    background-image: linear-gradient(60deg, #13630c 0%, #dca828 50%, #4a15ee 75%);
    color: white;
    opacity: 0.95;
    margin-bottom: 16px;
`

const About = () => (
    
    <Container>
        <HeroImage />
        <StyledImg src={process.env.PUBLIC_URL + '/profile.png'}/>
        <p>Hi, my name is Uday Sravan K (USK). You can call me USK or Uday.</p>
    
    <h4><Link to="/about/childhood">Childhood</Link></h4>
    <p>I was born in 1990 in a small village called Turakgudem which is located in the southern part of India. So I do know the village lifestyle and I'm so glad to have the experience of living real close to nature. I come from a middle class family background. My parents are professional tailors and also used to run a small textiles business. I have one elder brother who is passionate about computers and database technologies.</p>
    
    <h4><Link to="/about/education">Education</Link></h4>
    <p>10 years of my school and junior college happened mostly in a rural area in and around Kusumanchi division of Khammam district in Telangana, India.</p>
    <p>I hold a Bachelor of Technology degree in Electronics and Communication Engineering from SBCE (Swarna Bharathi College of Engineering) college which is affiliated to JNTU (Jawaharlal Nehru Technological University) University in Hyderabad.</p>
    
    <h4><Link to="/about/career">Career</Link></h4>
    <p>I am a software application developer since 2011. I started my career as a software trainee mainly working for products in the e-learning domain. I lived in Mumbai, Bangalore, and Hyderabad, cities of India to pursue different jobs. I have experience in building apps using cutting edge technologies like web, native Android, native iOS and react-native technologies.</p>
    <p>Currently I'm working as a Lead Engineer at Target Corporation in Minneapolis, USA. Target is the second largest retailer company in the US. </p>
    <p>As a developer I have shown interest in learning new technologies and adapting to new work cultures.</p>
    <p>Embracing change and coming out of my comfort zone has opened up a lot of opportunities throughout my career.</p>

    <h4>Hobbies</h4>
    <p>In my free time I like to play foosball, chess and table tennis. I love to watch action and adventure movies. I find immense pleasure and relaxation in visiting natural tourist places like hill stations, rivers, forests, historical monuments and traveling.</p>
    <p>Health and fitness are part of my routine life.</p>

    <h5>Watch this quick win game</h5>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/7Y5mD8dqgS8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <h4>Opinions & Beliefs</h4>
    <p>I'm God agnostic. I believe too much of anything is good for nothing. I work hard not only for money but also for a better lifestyle.</p>

    </Container>
);

export default About;