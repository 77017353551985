import React from 'react';
import styled from 'styled-components';

const HeroImage = styled.div`
    width: 100%;
    height: 150px;
    background-color: #1fc8db;
    background-image: linear-gradient(140deg, #EADEDB 0%, #9C70A4 50%, #6FD641 75%);
    color: white;
    opacity: 0.95;
    margin-bottom: 50px;
`

const LearnNewTechs = () => (
    <div>
        <HeroImage />
        <p>I learn most of the new technologies from the official websites and the content provided by authors of the technology. Throughout my career I have been switching between technologies. I started my career with Android development, then switched to iOS, then to Web, then to ReactNative and currenlty working on Android. I would like to possess versatile skills instead of being strong on one single technology.</p>
        
        <h3>What to learn?</h3>

        <p>I pick up the topics/technologies mostly based on the need or choice. First I research about what should I pick as my career path. For example, when I was working on Android, I had other similar options, like Windows development, iOS etc. I have decided to go with iOS though gathering the required hardware is expensive. Because I was confident about the iOS lifetime compared to the Windows OS. When I was working on Web technologies, a lot of new frameworks were in the market, like React, Angular, Vue etc. I didn't learn anything in advance, but I had some idea about major and popular frameworks. At my job, react was the one being picked up for the project. So, I started learning React. So, it was purely based on need. Since I know Android, Web and iOS, I thought of learning something which touches all these. So, I started thinking about ReactNative. I was excited to learn and start contributing to a project at my work. Fortunately, my employer, Target, provides excellent opportunities to learn and work on new technologies. Android development was something that always made me feel good, particularly because of the way Android gives the smartphone experience to most of the people in the world, unlike iOS, which is hard to afford by many. So, this is how I choose my career path.</p>

        <h3>Where to start?</h3>

        <p>Now I know what to learn. Next thing is where to start. I go to the official website. For example, to learn ReactNative, I go to https://reactnative.dev/. Most of these websites will give you the getting started link. You can start from there to installing the required software, setting up configurations and also documentation. I do not go too deep into anything at the beginning. I just follow the learning pattern setup by the author. Because I believe they know the best about how to navigate through the subject. I start coding very quickly even if I don't understand some concepts. This is how I understand some concepts which I couldn't understand while reading the theory. I would like to debug the code to understand in-depth. For example, I was able to understand the C language only after I got to know about the F7 trace option.</p>

        <h3>Build confidence</h3>

        <p>Now you have got yourself a little bit familiar with concepts and coding. Now how to gain confidence? Confidence comes from the broader picture. I see everything as a problem and a solution with a formula. If you do not know there is a formula exists, you can never apply it. So, you don't have to learn the formula but just know that it exists. So, you can learn and apply it when you find the need for it. To achieve this, I just go through all the concepts related to a framework or technology. This is how I build confidence. Another important thing is to keep yourself up to date. You can follow blogs/official websites/tweets etc. You can also check what's new periodically.</p>
    </div>
)

export default LearnNewTechs;