import React from 'react';
import styles from 'styled-components';
import { Link } from 'react-router-dom';

const StyledCardA = styles.div`
  padding: 15px;
  background-color: beige;
  margin: 15px;
`

const StyledCardB = styles.div`
  padding: 15px;
  background-color: darksalmon;
  margin: 15px;
`

const StyledCardC = styles.div`
  padding: 15px;
  background-color: aquamarine;
  margin: 15px;
`

const StyledP = styles.p`
  color: red;
`

const Notes = () => (
    <div>
        <StyledP>Note: These were my personal notes. So they are not well formatted and could be out of date.</StyledP>
        <Link to="/notes/android"><StyledCardA>Android personal interview notes</StyledCardA></Link>
        <Link to="/notes/ios"><StyledCardB>iOS personal interview notes</StyledCardB></Link>
        <Link to="/notes/web"><StyledCardC>Web technologies personal interview notes</StyledCardC></Link>
        <Link to="/notes/angular"><StyledCardA>AngularJS personal interview notes</StyledCardA></Link>
        <Link to="/notes/general"><StyledCardB>General concepts interview questions</StyledCardB></Link>
        <Link to="/notes/ds-algo"><StyledCardC>Data-structures and algorithms interview questions</StyledCardC></Link>
    </div>
)

export default Notes;