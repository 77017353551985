import React from "react";
import "react-image-gallery/styles/css/image-gallery.css"
import ImageGallery from "react-image-gallery";
import GalleryData from "./GalleryData.json"

const Gallery = () => {
  const [images, setImages] = React.useState(null)
  React.useEffect(() => {
    let shouldCancel = false
    const call = async () => {
      let photos = GalleryData.photos
      if (!shouldCancel && photos.length > 0) {
        setImages(photos.map(photo => ({
          original: `${photo.url}=w1024`,
          thumbnail: `${photo.url}=w100`,
          originalTitle: photo.originalTitle,
          description: photo.description,
        })))
      }
    }
    call()
    return () => shouldCancel = true
  }, [])
  return images ? <ImageGallery items={images} lazyLoad thumbnailPosition="top"/> : null
}
export default Gallery