import React from 'react';
import styles from 'styled-components'

const StyledTable = styles.table`
    border: 2px solid green;
`

const StyledTableContainer = styles.div`
  margin-top: 20px;
`

const StyledTd = styles.td`
    border: 1px solid green;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
`

const StyledTr = styles.tr`
    border: 1px solid green;
`

const StyledTh = styles.th`
    border: 2px solid green;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
`

const Education = () => (
    <div>
        <h4>Thanks for showing intersest in knowing more about my education!</h4>
        <br/>
        <details>
            <summary>Here is my entire education at a glance</summary>
            <StyledTableContainer>
                <StyledTable border="2">
                    <StyledTr><StyledTh>Duration</StyledTh> <StyledTh>Standard</StyledTh></StyledTr>
                    <StyledTr><StyledTd>1993 - 94</StyledTd> <StyledTd>Pre-school ( 3 years old )</StyledTd></StyledTr>
                    <StyledTr><StyledTd>1994 - 95</StyledTd> <StyledTd>1st</StyledTd></StyledTr>
                    <StyledTr><StyledTd>1995 - 96</StyledTd> <StyledTd>2nd</StyledTd></StyledTr>
                    <StyledTr><StyledTd>1996 - 97</StyledTd> <StyledTd>3rd</StyledTd></StyledTr>
                    <StyledTr><StyledTd>1997 - 98</StyledTd> <StyledTd>4th</StyledTd></StyledTr>
                    <StyledTr><StyledTd>1998 - 99</StyledTd> <StyledTd>5th</StyledTd></StyledTr>
                    <StyledTr><StyledTd>1999 - 2000</StyledTd> <StyledTd>6th</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2000 - 01</StyledTd> <StyledTd>7th</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2001 - 02</StyledTd> <StyledTd>8th</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2002 - 03</StyledTd> <StyledTd>9th </StyledTd></StyledTr>
                    <StyledTr><StyledTd>2003 - 04</StyledTd> <StyledTd>10th (Secondary School)</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2004 - 05</StyledTd> <StyledTd>Intermediate 1st year (a.k.a Pre University College)</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2005 - 06</StyledTd> <StyledTd>Intermediate 2st year (a.k.a Pre University College_</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2006 - 07</StyledTd> <StyledTd>B.Tech first year</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2007 - 08</StyledTd> <StyledTd>B.Tech second year</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2008 - 09</StyledTd> <StyledTd>B.Tech third year</StyledTd></StyledTr>
                    <StyledTr><StyledTd>2009 - 2010</StyledTd> <StyledTd>B.Tech final year</StyledTd></StyledTr>
                </StyledTable>
            </StyledTableContainer>
        </details>
        
        <br/>
        <p>We moved to a village when I was 3 years old. For pre-school, my parents joined me in a government school in the village which has only till 5th standard.</p> 
    <p>My mother is passionate about education. She couldn't wait to join me in school until I turned 5 years old, which is a pretty common age in most families. I was born in 1990 and my first day of school happened in 1993, which is when I was getting ready for my third birthday (less than 3 years old). LKG & UKG were not part of my schooling. So 2 years saved there. <span role='img' aria-label="Smily face emoji">😊</span> </p> 
    <p>For 1st, 2nd and 3rd standards, I have been to the government school in the village, which is just a few 100 meters away from home.</p> 
    <p>For 4th standard, my parents joined me and my brother in a private school in a different village which is 2 kms away from home.</p> 
    <p>For 5th standard, I went to a government school, which was also 2kms away from home.</p>
    <p>For 6th & 7th again, school changed back to the same school in 4th standard.</p>
    <p>From 8th to 10th again, school changed for a reason which has transformed me as a bright student from a poor student.</p>
    <p>My pre-university college (PUC / Intermediate) also started in the same village where I did my schooling. I chose my primary subjects as mathematics, physics and chemistry.</p>
    <p>Standing as a college topper in my first year of PUC took one of my maternal uncle's attention. So he moved me to a better college in a town called Khammam, which is 20kms from my home. I used to commute on public buses and private passenger auto rickshaws. It used to waste a lot of time and also makes me tired. So my mother again identified this problem and we all moved to Khammam. There I continued my remaining PUC course, i.e second year. I had seen a real computer for the first time when I was doing my PUC. Nothing computer related was part of my first 12 years of education.</p>
    <p>After completing PUC, in the summer holidays I learnt the C programming language. I was so thrilled to touch and do something on a computer. Talking to a computer through a programming language really mesmerized me. For this course, I paid 400 rupees, which is roughly 5 dollars.</p>
    <p>After PUC, with the guidance and full financial support of my maternal uncles, I started my bachelor's degree in technology. I have chosen a specialization called E.C.E, which stands for Electronics and Communication Engineering. For the first time, all my subjects were in English. Which has taken me through intense learning with great struggle. I literally used to look for the meaning of every word in dictionaries.</p>
    <p>My 12 years of education was in Telugu language. So language has been a challenge in the initial days of my graduation.</p>
    <p>10th, PUC and B.Tech in all, I have achieved distinction which is above 70% in certificate marks.</p>
    <p>The entire cost of my education is 100,000 Indian rupees, which is roughly 1500 dollars.</p>
    <p>I always had a wish to go for full-time higher studies in a top ranked university. But it didn't happen for many reasons.</p>
    </div>
);

export default Education;