import React from 'react';
import styles from 'styled-components';
import { Link } from 'react-router-dom';

const StyledCardA = styles.div`
  padding: 15px;
  background-color: beige;
  margin: 15px;
`

const StyledP = styles.p`
  color: blue;
`

const WorkExperience = () => (
    <div>
        <StyledP>My work experience.</StyledP>
        <h4>Summary</h4>
        <div>
            <ul>
                <li>
                Currently leading cart and checkout module of Target’s Android shopping application
                </li>
                <li>
                Total 9+ years (since Oct 6, 2011) of experience as a software developer in e-learning and e-commerce domains
                </li>
                <li>
                5+ years of experience on Android technologies with contribution to multiple apps in play store
                </li>
                <li>
                3+ years of experience on web technologies with major contribution to www.target.com web app
                </li>
                <li>1 year of experience on iOS technologies with contribution to Target iPad app</li>
                <li>
                6+ months of experience on React Native technologies with contribution to Pickup & Drive Up store app
                </li>
                <li>
                I would like to continue my career on native mobile/ web / hybrid technologies by associating with a product development team/ organization where I could get good exposure to new and innovative technologies with comprehensive learning for organizational and personal growth
                </li>
            </ul>        
        </div>
        <h4>Technologies</h4>
        <Link to="/experience/android"><StyledCardA>Android Technologies</StyledCardA></Link>
        <Link to="/experience/ios"><StyledCardA>iOS Technologies</StyledCardA></Link>
        <Link to="/experience/web"><StyledCardA>Web Technologies</StyledCardA></Link>
        <Link to="/experience/cross-platform"><StyledCardA>Cross-platform Technologies</StyledCardA></Link>
    </div>
)

export default WorkExperience;