import React from 'react';
import Gallery from "../components/gallery/Gallery";

const GalleryPage = () => (
    <div>
        <h3>Gallery</h3>
        <Gallery />
    </div>
);

export default GalleryPage;