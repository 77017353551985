import React from 'react';

const WebExperience = () => (
    <div>
        <h6>Experience on web technologies</h6>
        <h4>Summary</h4>
        <ul>
            <li>From 2017 – 19 worked on rebuilding target.com with technology stack of Node, Express, React 16.8.5, Redux, Thunk, Webpack, Yarn, NPM, Jest and more other supporting frameworks.</li>
            <li>Developed cart and checkout redesigns with enterprise checkout API integration, TypeScript, React hooks and react testing library etc.</li>
            <li>From 2016 – 17 worked for target.com using frameworks React, Backbone, JQuery, Bootstrap, RequireJS, SASS, Grunt, Karma, Jasmine etc.</li>
            <li>Have intermediate knowledge in building full stack web apps with Angular v1.6, Node, Express, Mongodb, RESTful web services, MySQL, Gulp (MEAN stack)</li>
            <li>Have beginner level experience and knowledge in deployments and building infrastructure for web apps.</li>
        </ul>
        <h4>Project links</h4>
        <p><a href="www.target.com">Target shopping website</a></p>
    </div>
)

export default WebExperience;