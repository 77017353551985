import React from 'react';

const AndroidExperience = () => (
    <div>
        <h6>Experience on Android Technologies</h6>
        <h4>Summary</h4>
        <ol>
            <li>
            Published 7 Android applications to play store. In house and multinational client projects.
            </li>
            <li>
            Proficient in using Android SDK API frameworks, Android support frameworks and other frameworks
like Volley, Picasa etc.
            </li>
            <li>
            Worked on Android features like fragments, services, broadcasts, localization, layouts, canvas,
animations, custom fonts, multi gestures, camera, volume control, vibration, Email, SMS, multimedia,
drag and drop, constraint layouts, network operations, data storage, SQLite, webview and multiscreen
support etc.
            </li>
            <li>
            Worked on REST, SOAP web services for downloading, saving User Generated Content (UGC) syncing
with multi platforms like iOS, windows8, flex, html5. Parsing of XMLs and generating JSON content.
            </li>
            <li>
            Third party library integrations like Facebook, ACRA, Hockeyapp.
            </li>
            <li>
            JNI integration of third-party licensed PDF rendering engines like MuPDF, PDFTron.
            </li>
        </ol>
        <h4>Freelance Project</h4>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/NUQqOgR_61E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <h4>Other project links</h4>
        <p><a href="https://play.google.com/store/apps/details?id=com.target.ui&hl=en_US" target="blank">Target Android Shopping Application (Team size 40+)</a></p>
        <p><a href="https://play.google.com/store/apps/details?id=com.hurix.kitaboo.cloudreader">Kitaboo eBook Reader</a></p>

        
    </div>
)

export default AndroidExperience;