
import React from 'react';

const IosExperience = () => (
    <div>
        <h6>Experience on iOS Technologies</h6>
        <h4>Summary</h4>
        <ul>
            <li>
            Worked on Target’s e-commerce shopping app for iPad.
            </li>
            <li>
            Integrated web checkout experience to native iPad app.
            </li>
            <li>
            Worked on native ePub player for iOS with reflow-able eBooks.
            </li>
            <li>
            It has features like pagination (wrote my own component), page navigation, bookmarks, font size adjust, text highlight, sticky notes etc.
            </li>
            <li>
            Have beginner level knowledge in Swift, Unity 3D integration and app store submission.
            </li>
        </ul>
         <h4>Project Links</h4>
        <p>The Target iPad app has been decommissioned and removed from the app store</p>
    </div>
)

export default IosExperience;