import React from 'react';
import {
    Nav,
    Navbar,
    NavDropdown,
} from 'react-bootstrap';
import styled from 'styled-components';
import { LinkContainer } from 'react-router-bootstrap';
import { FaX, FaGithub, FaStackOverflow, FaYoutube, FaLinkedin } from 'react-icons/fa6'

import logo from '../../logo.svg';

const StyledImg = styled.img`
  width: 64px;
  height: 64px;
`

const NavigationBar = () => (
    <Navbar bg="light" expand="lg" >
        <Navbar.Brand>
            <LinkContainer to="/">
                <StyledImg src={logo} className="App-logo" alt="logo" />
            </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <LinkContainer exact to="/">
                    <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to="/about">
                    <Nav.Link>About</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to="/blogs">
                    <Nav.Link>Blogs</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to="/gallery">
                    <Nav.Link>Gallery</Nav.Link>
                </LinkContainer>
                <NavDropdown title="More" id="basic-nav-dropdown">
                    <NavDropdown.Item>
                        <LinkContainer exact to="/experience">
                            <Nav.Link>Experience</Nav.Link>
                        </LinkContainer>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <LinkContainer exact to="/notes">
                            <Nav.Link>Notes</Nav.Link>
                        </LinkContainer>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <LinkContainer exact to="/contact">
                            <Nav.Link>Contact</Nav.Link>
                        </LinkContainer>
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Navbar>
                <Nav.Link href="https://www.linkedin.com/in/uday-sravan-kamineni-65135321" target="_blank" className="p-2">
                    <FaLinkedin color="#0e76a8" size={24}/>
                </Nav.Link>
                <Nav.Link  href="https://github.com/UdaySravanK" target="_blank" className="p-2">
                    <FaGithub color="#000000" size={24}/>
                </Nav.Link>
                <Nav.Link href="https://stackoverflow.com/users/1897654/uday-sravan-k" target="_blank" className="p-2"> 
                    <FaStackOverflow color="#f48024" size={24}/>
                </Nav.Link>
                <Nav.Link href="https://www.youtube.com/channel/UCo_c5UHweF2nr-Gr8aHS5fw" target="_blank" className="p-2">
                    <FaYoutube color="#c4302b" size={24}/>
                </Nav.Link>
                <Nav.Link href="https://x.com/usk_kamineni" target="_blank" className="p-2">
                    <FaX color="#1DA1F2" size={24}/>
                </Nav.Link>
            </Navbar>
        </Navbar.Collapse>
    </Navbar>
)

export default NavigationBar;