import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-flow: column;
`

const Contact = () => (
    <Container>
        <p>You can reach out to me at <a href="mailto:udaysravank32@gmail.com">udaysravank32@gmail.com</a></p>
    </Container>
);

export default Contact;