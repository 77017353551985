
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-flow: column;
    `
const HeroImage = styled.div`
    width: 100%;
    height: 150px;
    background-color: #1fc8db;
    background-image: linear-gradient(100deg, #a398c4 0%, #6042bb 50%, #7f0b4f 75%);
    color: white;
    opacity: 0.95;
    margin-bottom: 50px;
`

const Para = styled.p`
    text-indent: 32px;
`
const ApplySoftwreJobNotionsInLife = () => (
    <Container>
        <HeroImage />
        <h2>Apply Software Job Notions In Life</h2>
        
        <Para>I believe learning and applying is the basic nature of human life. Where we learn and where we apply can be absolutely 2 irrelevant areas. In this post I'm going to talk about how the notions we learn in software jobs can be applied in real life.</Para>

        <p>Instead of using pure software terminology, I try to keep it as simple as possible. Because this blog is not just for software job holders.</p>
        <h3>Time Management</h3>
        <Para>Time management is the biggest continuous learning concept in a software job. Some of the general practices are making a to-do list, prioritizing, executing in a sequence or even in parallel mode to save time. This can be applied in real life too.</Para>

        <h3>Go by Problem & Solution approach</h3>
        <Para>In the software domain, most of the cases, the origin of work comes from solving problems. The first step is to identify the problem with respect to a concerning thing. Like why customers are canceling their subscription services. The next step is to identify the potential cause or root cause. The next step is working on solutions and the last step is to implement them. But there is one more step to see if the solution is working or not. If yes, try to make it much better. If not, try another solution. This is called an iterative process. This concept can be applied in real life too. Say you are not happy with your fitness condition, like strength, appearance etc. You have to first identify the problem. Like is it because of food, or because of no exercise or lack of sleep? Then you have to work on identifying a solution and start implementing it. Also, check for results and iterate.</Para>

        <h3>Separation of Concerns</h3>
        <Para>This is one of the most popular and pretty widely followed concepts in the software development field. The concept is to isolate different responsibilities and concerns. While you are making a decision specific to one concern, if you put all others concerns into the same thought process, it can put you in a dead loop and takes you nowhere. Instead of mixing different concerns, you should be able to think through them in an isolated fashion. The same concept can be applied in real life as well. Example, If you are thinking about financial matters, don't mix them up with career problems. While thinking about a career, don't mix up with personal relationship problems. I understand that a lot of these are related & connected, because, after all, everything is about you, and you are connected to all of these. But you still have to learn about how to separate them in your mind and in your activities. Activity example, say you are playing with your kids, just try to spend quality time without doing something related to your work in the phone or laptop. The more you learn, the better it all gets.</Para>


    </Container>
);

export default ApplySoftwreJobNotionsInLife;