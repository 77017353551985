import React from 'react';

const CrossPlatformExperience = () => (
    <div>
        <h6>Experience on cross-platform technologies</h6>
        <h4>React Native</h4>
        <p>I was working for an in-store interanl application built for Target store employees. The application was targetted for a custom Android OS device. App was built on React-native technology. I was one of the engineer contributed for feature development, defect fixing etc. The tech stack was React 17, JS&TS, Redux, Stylesheet etc.</p>
        <h5>Open Source Project</h5>
        <p><a target="blank" href="https://www.npmjs.com/package/rn-swipe-button">React Native Swipe Button</a></p>
        <h4>ePub Reader</h4>
        <p>I had initiated a project to read epub content. I was developing a product using Web, Android and iOS technologies.</p>
    </div>
)

export default CrossPlatformExperience;