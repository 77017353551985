
import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import NavigationBar from './components/navigation-bar/NavigationBar'
import Home from './containers/Home'
import About from './containers/About'
import Notes from './containers/Notes';
import Childhood from './containers/Childhood';
import Education from './containers/Education';
import Career from './containers/Career';
import AndroidNotes from './containers/AndroidNotes';
import IosNotes from './containers/IosNotes';
import WebNotes from './containers/WebNotes';
import GeneralNotes from './containers/GeneralNotes';
import AngularJsNotes from './containers/AngularJsNotes';
import DsAndAlgoNotes from './containers/DsAndAlgoNotes';
import Blogs from './containers/blogs/Blogs';
import LearnNewTechs from './containers/blogs/LearnNewTechs';
import WorkExperience from './containers/WorkExperience';
import AndroidExperience from './containers/AndroidExperience';
import IosExperience from './containers/IosExperience';
import WebExperience from './containers/WebExperience';
import CrossPlatformExperience from './containers/CrossPlatformExperience';
import Contact from './containers/Contact';
import GalleryPage from './containers/GalleryPage';
import WhatDoIThinkAboutFitness from './containers/blogs/WhatDoIThinkAboutFitness';
import ApplySoftwreJobNotionsInLife from './containers/blogs/ApplySoftwreJobNotionsInLife';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVFx_yA2lCIL8ARbYnq8BYJFrB5hhDseE",
  authDomain: "udaysravank.firebaseapp.com",
  projectId: "udaysravank",
  storageBucket: "udaysravank.appspot.com",
  messagingSenderId: "1002859000827",
  appId: "1:1002859000827:web:4fe493bf0545b66878d15e",
  measurementId: "G-HQHTZ5CVXN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() { 
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", { "page_path": location.pathname })
  }, [location]);

  return (
    <div>
      <NavigationBar />
      <Container className="p-4">
        <Switch>
          <Route exact path="/about/childhood">
            <Childhood />
          </Route>
          <Route exact path="/about/education">
            <Education />
          </Route>
          <Route exact path="/about/career">
            <Career />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/notes/android">
            <AndroidNotes />
          </Route>
          <Route exact path="/notes/ios">
            <IosNotes />
          </Route>
          <Route exact path="/notes/web">
            <WebNotes />
          </Route>
          <Route exact path="/notes/angular">
            <AngularJsNotes />
          </Route>
          <Route exact path="/notes/general">
            <GeneralNotes />
          </Route>
          <Route exact path="/notes/ds-algo">
            <DsAndAlgoNotes />
          </Route>
          <Route exact path="/notes">
            <Notes />
          </Route>
          <Route exact path="/blogs">
            <Blogs />
          </Route>
          <Route exact path="/blogs/learn-new-technologies">
            <LearnNewTechs />
          </Route>
          <Route exact path="/blogs/apply-software-job-notion-in-life">
            <ApplySoftwreJobNotionsInLife />
          </Route>
          <Route exact path="/blogs/what-do-i-think-about-fitness">
            <WhatDoIThinkAboutFitness />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/experience">
            <WorkExperience />
          </Route>
          <Route exact path="/experience/android">
            <AndroidExperience />
          </Route>
          <Route exact path="/experience/ios">
            <IosExperience />
          </Route>
          <Route exact path="/experience/web">
            <WebExperience />
          </Route>
          <Route exact path="/experience/cross-platform">
            <CrossPlatformExperience />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/gallery">
            <GalleryPage />
          </Route>
        </Switch>
      </Container>
    </div>
  )
}

export default App;
