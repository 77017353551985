import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-flow: column;
`

const HeroImage = styled.div`
    width: 100%;
    height: 150px;
    background-color: #1fc8db;
    background-image: linear-gradient(140deg, #EADEDB 0%, #9C70A4 50%, #6FD641 75%);
    color: white;
    opacity: 0.95;
    margin-bottom: 50px;
`
const Para = styled.p`
    text-indent: 32px;
`
const WhatDoIThinkAboutFitness = () => (
    <Container>
        <HeroImage />
        <h2>What do I think about fitness?</h2>
        <Para>In my opinion, fitness is an investment you make in yourself which travels with you till the end. Fitness is not something we should keep as an option, but we should treat it like a daily routine, as important as eating food or sleeping at night.</Para>
        <Para>When it is about fitness, simply what you eat is what you are.</Para>
        <Para>Fitness mostly comes from your eating habits, not completely from exercise. Exercises are to help with building stamina, shape up your body or for some other specific goals.</Para>
        <p>The 2 most important habits which contribute to 90% of your fitness</p>
            <ol>1. Healthy sleeping habits</ol>
            <ol>2. Healthy eating habits </ol>
        <p>These are not something we do occasionally or optionally. So, you need to understand that you just have to improve these habits.</p>
            
        <h3>Summary of what I learned</h3>
        <p>These are the important things I got to know in many years of attention and effort through fitness in life.</p>
        <ol>
        <li>Most people need just 2000 calories per day. If you consume more, you should burn it next morning.</li>
        <li>A flat stomach comes from a simple walk or running every day. No need for plans for intense workouts or special training plans. Just try to walk 6k-10k steps a day.</li>
        <li>Most healthy foods are not tasty. Taste is only for your tongue, but what it does after it crosses your tongue is the most important thing.</li>
        <li>Eat only 70% of your belly.</li>
        <li>Do 16–20 hours of fasting once or twice a month.</li>
        </ol>
        <p>Good luck if you have something to take away from this reading.</p>
    </Container>
);

export default WhatDoIThinkAboutFitness;