import React from 'react';
const DsAndAlgoNotes = () => (
    <div>
        <pre>
            {`
                Data Structures and Algorithms


                http://www.studytonight.com/data-structures
                 
                
                Bubble Sort
                
                1.     In every iteration the biggest will go to end the array.
                
                2.     Space complexity for Bubble Sort is O(1), because only single additional memory space is required for temp variable
                
                3.     Best-case Time Complexity will be O(n), it is when the list is already sorted.
                
                4.     Worst-case Time Complexity will be O(n2)
                
                 
                
                Insertion Sort
                
                1.     Starts with second element and moves it to forward by comparing with its previous elements.
                
                2.     Worst Case Time Complexity : O(n2)
                
                3.     Best Case Time Complexity : O(n)
                
                4.     Average Time Complexity : O(n2)
                
                5.     Space Complexity : O(1)
                
                 
                
                Selection Sort
                
                Selects the smallest element and swaps with first element.
                
                Selects the second smallest with second element
                
                 
                
                Quick Sort
                
                Divide and merge. pivot can be first element
                
                private void quickSort(int low, int high)
                
                
                Read more: http://javarevisited.blogspot.com/2014/08/quicksort-sorting-algorithm-in-java-in-place-example.html#ixzz4jiVxsrw9
                
                
                Merge Sort
                
                Divide and Conquer
                
                Important Formulas
                
                1.     (n-1)+(n-2)+(n-3)+.....+3+2+1 then Sum = n(n+1)/2
                
                Depth First Search
                
                1.     Traversing from one Vertex to all next vertexes until reaching end
                
                2.     Uses stack
                
                3.     AB
                
                Breadth First Search
                
                1.     First checks all adjucent vertexes to a node
                
                2.     Uses Queue
                 
                
                Coding Test sample apps
                 
                
                1.     Write code for Tic-Tac-Toe game
                
                2.     Create Simple Chatting app
                 
                
                private void quickSort(int low, int high) { 
                
                    int i = low; int j = high; 
        
                    int pivot = input[low + (high - low) / 2]; 
        
                    while (i <= j) {  
                        while (input[i] < pivot) {
                            i++;  //find bigger than pivot in left side
                        } 

                        while (input[j] > pivot) {
                            j--; //find smallar than pivot in right side
                        } 

                        if (i <= j) {
                            swap(i, j);  
                            i++; j--;
                        }
                    } 
        
                    if (low < j) {
                        quickSort(low, j);
                    } 
        
                    if (i < high) {
                        quickSort(i, high);
                    }
                }
                
                Notes:
                
                1.     if we divide an array half every time using while loop then time complexity would b log(N).
                
                2.     NOTE : In general, doing something with every item in one dimension is linear, 
                doing something with every item in two dimensions is quadratic, and dividing the working area in half is logarithmic.
                
                3.     Big Oh denotes "fewer than or the same as" <expression> iterations.
                
                4.     Big Omega denotes "more than or the same as" <expression> iterations.
                
                5.     Big Theta denotes "the same as" <expression> iterations.
                
                6.     Little Oh denotes "fewer than" <expression> iterations.
                
                7.     Little Omega denotes "more than" <expression> iterations.
                
                8.     O(expression) is the set of functions that grow slower than or at the same rate as expression.
                
                9.     Omega(expression) is the set of functions that grow faster than or at the same rate as expression.
                
                10.  Theta(expression) consist of all the functions that lie in both O(expression) and Omega(expression).
                
                11.  stable sort: after sorting order of equal elements is maintained
                
                12.  PreOrder Traverse of Tree is like Depth First Search start from top of the tree abc (a parent of b and c)
                
                13.  In Order Traverse is like DFS but starts from bottom of tree. Same as Pre order i.e bac(a parent of b and c
                
                14.  Post Order is like Breadth First Search starts from bottom. bca (a is parent of b and c)
                
                15.  level order: prints all element of first level then next level then next etc. 
                a bc mnop (here b parent of m,n and c parent of o,p)
                
                16.  Big Oh notation O: measures the worst case time complexity of an algorithm.
                
                17.  Omega notation : best case time
                
                18.  Get top element of a stack without removing it -> use peek(). Because pop() removes top element.
                
                19.  pop operation on array decrements top to lower position but pop operation on linked list 
                removed the top element means deallocates the space.
                
                20.  a=-1, ++a , value of a in same executing line is 0 / a=-1, a++, a is -1
                
                21.  Bubble sort O(n2)
                
                22.  Insertion sort .Pick a element and insert at correct place in left side sub array. O(n2). Not good for large data
                
                23.  Selection sort. Select smallest values and put at beginning of right side sub array. O(n2)
                
                24.  merge sort. divide till single elements. 
                1. take left part and right part. for loop with length of one part. 
                compare left array first element with right array first element. 
                what ever is small put into new array and move pointer of first array to second element. 
                2. What ever elements left in right or left arrays put them directly in new array. 
                3. put all new array elements into actual array at same index positions.
                
                25.  Stacks used for recursive methods. Last in first out.
                
                26.  Queues used for os level functions like touch events,click interactinos First in first out
            `}
        </pre>
    </div>
)

export default DsAndAlgoNotes