import React from 'react';

const Childhood = () => (
    <div>
        <h5>Thanks for showing interest in knowing about my childhood.</h5>
    
    <br/>
    
    <p>I will talk about 12 years of my childhood life.</p>
    
    <p>We moved to a village from a town when I was 3 years old. Half of the alphabets I had learned in a town and remaining half in a village government school.</p> 
    
    <p>At the age of 7 I was in 4th standard. My parents sent me to a private school which is 2kms away from home. My father has arranged a man to carry me and my brother to school on a bicycle.</p>
    
    <p>For 5th standard again, my parents moved me to a different school. This time it is again a government school which is also 2kms away from home. But we lived in a government hostel for a year to complete 5th standard.</p>
    
    <p>For 6th & 7th standard again, my parents moved me back to the same school where I studied 4th standard, which is a private school. I was 10 years old. As we grew up, my brother and I used to walk to school from home. The rocky and dusty road to school is still in my mind, as fresh as it was yesterday. In the morning it was kind of walking through the woods by listening to birds twitters and cool breezes. In the evening it used to be a little scary as it got dark and the road was through agricultural lands.</p>

    <p>At the age of 10, I had started loving nature. We used to live in my grandmother's 100 years old house, which is close to agricultural lands. We don't have any lands as we are Tailors professionally. After school and on holidays I used to walk into the woods and spend time at trees, small water streams and fishing in ponds, etc. I used to cultivate vegetable plants in the backyard of my home. Everyday after coming back from school, I sit at those plants and do my homework. When they flourish and give me vegetables, I used to feel immensely happy and proud. By using small water pipes I used to water plants exactly to their roots. At that time I had no idea that it was called a drift irrigation system.</p>

    <p>In 6th and 7th standard school days, teachers used to punish students by beating with sticks for not finishing homework or for some other mistakes. I used to be so scared and never really got into any learning. I was really poor in every subject. Once, when a teacher slapped me, I took it very seriously and studied the entire night without sleeping. Next day in the school, I had fainted and experienced memory loss for half a day.</p>

    <p>My mother understood that the pressure due to fear and self-respect was pulling me down in my studies. So she changed me to a different private school for 8th standard where there won't be much punishment. Just in a year, I had started showing excellent progress in scores. I had continued here till my 10th standard. I was one of the top students. I also used to earn prizes by participating in various activities.</p>

    <p>Most of the time I used to be a lonely boy. Not because of no friends but because of choice. I play alone all by myself</p>
    
    <p>I dreamt of becoming a scientist in childhood, though I had no idea how to start and where to start. When I saw a picture of the great mathematician Srinivisa Ramanujan on the last page of a mathematics book, I was so thrilled to see a picture like that. Working on something so hard and waiting for results used to make me so happy. So I used to fix broken electronics and small mechanical toys of friends and in my home. I used to play a lot with small DC motors grabbing from damaged music players. I used to fix them by giving positive and negative charging nodes without any subject knowledge on DC motors. I tried to make small toy gadgets with 5V batteries and used to present them in kids exhibitions.</p>
    </div>
);

export default Childhood;