import React from 'react';
import styles from 'styled-components';
import { Link } from 'react-router-dom';

const StyledCardA = styles.div`
  padding: 16px;
  background-color: beige;
  border: 1px solid green;
  margin: 15px;
`

const StyledCardB = styles.div`
  padding: 15px;
  border: 1px solid blue;
  background-color: darksalmon;
  margin: 15px;
`

const StyledCardC = styles.div`
  padding: 15px;
  border: 1px solid gray;
  background-color: aquamarine;
  margin: 15px;
`

const Blogs = () => (
    <div>
        <Link to="/blogs/learn-new-technologies"><StyledCardA>How do I learn new technologies?</StyledCardA></Link>

        <Link to="/blogs/apply-software-job-notion-in-life"><StyledCardB>Apply software job notions in life</StyledCardB></Link>

        <Link to="/blogs/what-do-i-think-about-fitness"><StyledCardC>What do I think about fitness?</StyledCardC></Link>
    </div>
)

export default Blogs;