import React from 'react';
const GeneralNotes = () => (
    <div>
        <pre>{`
            Common Skills           
            Object Creational Pattern
            
            1. Factory  - creates obj based on factory class constructor argument type(shape - circle, rect,square)
            
            2. Singleton  - Maintains single instance of an object
            
            3. Prototype  - Clones the object of already created and stored in a hashtable or somewhere
            
             
            
            Structural Pattern
            
            4. Adapter  - bridge between two incompatible interfaces.
            
             
            
            Behavioral Pattern
            
            5. Command - uses execute method to completed tasks for registered/added commands. Nothing but list of added methods)
            
            6. Observer - Subject, Observer and Client. When Subject changed observer will notify all registered clients.
            
             
            
            Others Patterns
            
            7. Data Access Object Pattern
            
                 - DAO interface to hold operations to be performed on a model.
            
                 - DAO concrete to get data from data sources 
            
                 - Model Obj/ Value Obj - a class of getter setters 
            
            8. MVC - separates view and model using controller  
            
            9. Front Controller Pattern
            
                 - provide a centralized request handling mechanism. so that all requests will be handled by a single handler.
            
                 - Front Controller class uses Dispatcher class to dispatch request to a Handler
            
                 - View class - requests made from views 
             
            
            General
            
            1.     An automorphic number is a number whose square ends in the same digits as the number itself. For example, 6 = 36, 5 = 25, 25 = 625
            
            2.     Polyndrome: 121, 252 etc
            
            3.     Armstrong : 153 = 1**3 + 5**3 + 3**3 ;// 0,1, 153, 370, 371 , 497 etc
            
            4.     Perfect sum of all divisors of N equals to N. 6 divisors 1,2,3 and 1+2+3 = 6 so 6 is perfect number.
            
            5.     A Duck number is a number which has zeroes present in it, but there should be nozero present in the beginning of the number. For example 3210, 7056, 8430709 are all duck numbers whereas 08237, 04309 are not.
            
            6.     Fibonacci sequence: 0, 1, 1, 2, 3, 5, 8 etc
            
            7.     Scalars will have only magnitude no direction
            
            8.     altitude - height of a flying object
            
            9.     acceleration is rate change of speed
            
            10.  BMI = should be around 0.25 . height in meters/ weight in kgs
            
            11.  1km = 0.62 miles (mile is more)
            
            12.  1m = 3.28 feet
            
            13.  Difference bw radians and degrees
            
            14.  Diff bw celcious and Fahrenheit c = (f-32) 5/9
            
            15.  bit masking used to store multiple enable/disable flags in one number
            FeatureA = 0001
            FeatureB = 0010
            FeatureC = 0100
            To enable only feature A and C and send in one number do as below
            FeatureA | FeatureC => 0001 | 0100 = 0101 = finalSendValue
            Now to check is FeatureA enabled or not do as below
            if(finalSendValue & FeatureA == FeatureA) because 0101 & 0001 = 0001 so Feature A enabled has been identified using 0101
            
            16.  http://www.mytechinterviews.com/how-strong-is-your-egg
            
            17.  how random numbers/ pseudo random number generators works? hard ware real random number generators?
            
            18.  Google expects dash separated name of files. ex my-first-post.html
            
            19.  This_is_a_single_word , but this-is-multiple-words
            
            20.   
            
            
            isEven = function (n) {
                return ((n>>>0).toString(2) & 1) == 0;
            }
            
            
            isFloat = function (n) {
                return n%1!==0;
            }
            
            isFloat = function (n) {
                return n === +n && n !== (n|0);
            }
        `}</pre>
    </div>
)

export default GeneralNotes