import React from 'react';

const Career = () => (
    <div>
        <h4>Thanks for showing interest in knowing more about my career!</h4>
    <br/>
    <p>After completing my graduation, I was really in the need of earning money. So dropped from the idea of going for higher education.</p>

    <p>I wanted to be strong in some programming language, for that I had decided to teach it instead of joining some courses.</p>
    
    <p>Started teaching Java core programming concepts to college students in a CMC (Computer Maintenance Corporation) institute backed by the TATA companies.</p>
    
    <p>While teaching I also used to attend some interviews and I was also trying to start something of my own, which is a kind of institution to teach software skills to college graduates and students in engineering colleges.</p>
    
    <p>I had gone through a lot of interviews. My understanding about cracking interviews is like 70% skills and 30% luck. I also had rejected a few opportunities and finally in 2011 October I went through a competitive interview process for my first job. Among 300 participants, the selection process sifted in stages based on various criteria and selected 60 members, then 30, 18, 12, 6 and 4. I'm one of those 4.</p>
    
    <p>I started my career as a software trainee engineer. I was trained on Android and HTML technologies for 30 days in Hyderabad. After training I was transferred to start my first job working for Hurix Systems Pvt Ltd in Mumbai. At Hurix as a software engineer I used to build features for an in house product called Kitaboo ebook reader.</p>
    
    <p>After working on Android for 2 years, I decided to learn some other technology. Decided to learn iOS but I had no MacBook. After 6 months of great struggle, finally management provisioned me a Mac Mini at the office. Management challenged me to start working on iOS within 7 days. I had put myself through comprehensive self-learning for those 7 days, spent each day, more than 16 hours time.</p>
      
    <p>After a few months, since I know iOS, Android and web technologies, management wanted me to start building a product from scratch called ePub reader, for which I used to code on all three platforms in parallel.</p>

    <p>In 2014, I decided to experience work-life in a large company of 10,000+ employees and in a team size of 50+ engineers collaborating for a single product. I had relocated to Bengalore to join Target Corporation as a senior software engineer. I had joined as an Android engineer but started working for iPad applications as there was a need for iOS engineers and I was very much interested to work on new technologies.</p>
    
    <p>In 2015, iOS flagship iPad shipping application development moved to USA headquarters location so I was left with options to move to a different mobile apps team or to work on web technologies.</p>
    
    <p>I used to embrace change and learn new things, so I moved to web technologies. I was working for Target’s online shopping website, primarily for Americans shoppers. This has given me the opportunity to work on a tech stack of javascript, html, css, jquery, backbone, require, grunt, memcache etc. </p>
    
    <p>We started rebuilding the entire product using React Node & Redux tech stack with CI-CD pipeline. I was primarily focused on cart and checkout functionalities and flexible fulfillments on product details pages.</p>
    
    <p>In 2018, it has been decided to move the majority of the web development to a headquarter location. 2 out of 40 front-end engineers were selected to transfer to Minneapolis headquarters. I had made up my mind to have overseas work experience and also see the other side of the world. Relocated to Minneapolis in 2018 to work on web technologies as a cart and checkout business domain specialist.</p>
    
    <p>It has been more than 4 years that I have been on web technologies. I was looking for a change, then I grabbed an opportunity to work on react-native technologies. Since I was already into web technologies and also with native apps development experience, it didn’t take much time for me to pick up speed on react-native work. </p>
    
    <p>After 6 months, I wanted to have a long term career in something other than web based technologies. So grabbed another opportunity to work onAndroidtechnologies as a lead engineer. Currently I’m working forAndroidflagship shopping application development, specialized in the bottom of the funnel that is the cart and checkout module of the app. Since the time I left working on Android in 2014 to the day I came back to it, theAndroidtechnology ecosystem has transformed rapidly. I’m still going through a lot of learning.</p>
</div>
);

export default Career;