import React from 'react';

const IosNotes = () => (
    <div>
        <h4>iOS technologies persona interview notes from year 2015</h4>
        <pre>{`
            1. Why header files? can we import .m file also?

            2. Compiler converts from which to which format? and name of compiler? .m to executable files
            
            3. is Objective-C a procedural? what is procedural language? and what r other types of prog langs?
            
            4. Do you know user interactive components like button,textfields,dropdown, radio,scroll view etc?How they work?
            
            5. What do u know bat ARC?
            
            6. Do you know evolution of iOS,apple? and what are latest in iOS like version feature ,problems etc?
            
            7. What happens to log messages in production build?
            
            8. Can you write/readout syntax of a block?
            
            9. Debugging tips?How to see crash stack report from Xcode?
            
            10. How to support multi screens.know differnet devices and resolutions
            
            11. What are different storages we use in iOS ,How to access database?
            
            12. Should class name of header file and file name must be same? what about .m file? what about multiple classes?
            
            13. What is alloc,malloc,calloc,realloc,free ?
            
            14. Do you how to write unit test cases for an iOS app? any automation process about iOS app?
            
            15. Hav u encoutered memory issue while developing app and how u fixed.
            
            16. Explain about app/view life cycle
            
            17. What happens if delegate method called but not implemented in listener class?
            
            18. Supporting audio file formats?
            
            19. What is forward declaration of class and why?
            
            20. Storyboards uses which language?
            
            21. what is isa,id?
            
            22. How do u support localization?
            
            23. How many types of libraries we have ?diff bw static lib and dynamic lib?
            
            24. Do you know about pod files?
            
            25. What is dSYM file job?
            
            26. LLVM stands for?
            
            27. What is code signing?
            
            28. Dynamic Lib ,Static Lib, Bundle, Relocatable Obj file?
            
            29. How to lock orientation?
            
            30. Diff bw category and subclass
            
                 cat - can only add new methods to existing class , canÕt add properties or variables
            
            31. Diff bw variable and property
            
                 prop are declare+synthasize auto handle for memory management
            
            32. what is .ivar  -internal variable like _varialbe inside get ,setter methods
            
            33. Can you write syntax for custom protocol?
            
            34. How to type case string to int?
            
            35. diff bw id(instance of any class) and instancetype( definitely receiving 
                an instance of the same class or subclass of the class)?
            
            36. How can I access single keychain login info for two apps 
            in device.?ans by giving same app seed id to both apps.
            
            37. Split view ,picture in picture,3d touch (Peek and Pop), iPad pro, wallet, Slide Over?
            
            38. File Template library, code snippet library, object library,Media library in Xcode?
            
            39. Reflection to show iPad screen on desktop?
            
            40. What is anonymous categories (i.e extensions) and diff bw subclass,category,extensions and protocol?
            
            41. Extended instance variable are private or public?
            
            42. Accessors for atomic properties must both be either generated or user-defined?
            
            43. Do we have access specifiers in iOS, if yes then how do they work?What is default?
            
            44. @dynamic tells to compiler implementation is some where else in super classes
            
            45. Terminal command to Generat .pem file from exported .p12. APNs
            
            46. Ways to declare global constants and types of constants?
            
            47. Have you worked with UIWebView and how to call js methods and vice versa?
            
            48. How touch propagate works in iOS?
            
            49. Diff bw release and auto release , copy and retain?
            
            50. wt is memory leak?non released with zero ref count
            
            51. dangling pointer leads to invalid memory address,undefined,call release two times
            
            52. dynamic memory and static memory
            
            53. extern keyword say to runtime that obj definition is somewhere external. 
            means not in same file.which gives linking error
            
            54. static allocates memory at compile time. Compilation errors
            
            55. calloc initializes all bytes in the allocation block to zero. allocates memory in runtime
            
            56. malloc initializes all bytes in the allocation block to undefined. allocates memory in compile time
            
            57.stack memory - fast,local variable only,no resize
            
            58. heap memory - slow,global,resize
            
            59. What is DRY principle
            
            60. //Create 500 bytes on the stack char buffer[500]; //Create 500 bytes on the heap 
            pBuffer = new char[500];
            
            61. wt is stack over flow
            
            62. How strong reference ,week reference works
            
            63. Have u worked on web services ? what do u know about RESTful services, SOAP services.
            
            64. Diff bw get,post methods
            
            65. How iOS file storage works.is there any public space for all apps? how to access it?
            
            66. How do u achieve caching of images to long scrolls in shopping apps?
            
            67. Learn about cocoa frameworks?
            
            68. Have you worked on animations? Which library u have used?
            
            69. How do achieve log running background tasks in iOS?
            
            70. How do you support multiple screens?
            
            71. Tell few resolutions of apple devices?
            
            72. Do you how to integrate crash reporters and third party frameworks like twitter, Facebook?
            
            73. What are the sensors you know in apple devices?
            
            74. Do you know swift ? Have you tried working on it? anything intersting u found on it?
            
            75. Did you work on maps?
            
            76. Explain Encapsulation, polymorphism, inheritance ?
            
            77. Do you about apple watch?
            
            78. Explain flow of app store submission process?
            
            79. Do you any versioning tool like git,svn,bit bucket?
            
            80. Do you answer stack overflow questions?
            
            81. How to terminate for loop with out keywords?
            
            82. How GCD works? What is it?
            
            83. Simulator vs emulator?
            
            84. diff bw CGFloat and float?
            
            85. what is key value coding? and KVO?
            
            86. xml vs json?
            
            87. get vs post?
            
            88. Do you know any lldb commands?
            
            89. MIME stands for? Multipurpose Internet Mail extensions
            
            90. JPEG - joint photograph expert group? Portable Network Graphics
            
            91. json - java script object notation
            
            92. xml extended markup language
            
            94. GCC- GNU Compiler Collection
            
            95. GDB - GCC Debugger
            
            96. LLVM - Low Level Virtual Machine
            
            97. What is kernel ? The program in a Unix-like system that allocates machine resources 
            and talks to the hardware is called the ÒkernelÓ.
            
            98. Default values for If you donÕt initialize NSString ,NSInteger,int,CGFloat ?
            
            99. Projects you worked followed which methodologies?
            
            100. NULL = (void*)0 ,Nil = (class)0, nil = (id)0
            
            101. diiffenec bw deprecated and obsolete ?
            
            102. How to write conditional importing? yes by using preprocessors Conditional compilation.
            
            103. compilation of Objective-C programs from source code to executable binary is a three phase process
            
            phase 1 : a tool called the preprocessor scans the human written source code
             and converts it to compiler friendly content and format
            
             
            
            phase 2 : In the second phase the compiler generates object code 
            (usually in the form of file with a .o filename extension) from the preprocessed source code
            
             
            
            phase 3 : Finally, the linker brings all the object code modules and libraries together, 
            resolves symbol references and creates the executable binary.
            
             
            
            104. How to communicate bw apps on same device and other devices?
            
            105. What is the difference between NS_ENUM and NS_OPTIONS?
            
            106. Super class of AppDelegate class is UIResponder
            
            107. makeKeyAndVisible method does what?
            
            108. Dynamic library vs static library (static framework  is compiled .a file ,static library is subproject)
            
            These are the only things I am aware of as of now . Will add here If I find something else
            
             
            
            109. 4 Tablets
            
            110. How would you fill out exact 4 liters in a Can of 5 liters and another Can of 3 liters, 
            with unlimited supply of water?Ó
            
            111. what is the angle between the hour hand and minute hand in between 2:20
            
            112. What is agile ?
            
            http://www.tutorialspoint.com/sdlc/sdlc_agile_model.htm 
            
            http://www.agilenutshell.com/ 
            
             
            
            113. What is SDLC?
            
            114. Sort two numbers without using temp
            
            115. a*b = b*a commutative principle
            
            116. perfect squares will have odd no of factors (ex 1,4,9,16 (factors for 16 Ð 1,2,4,8,16)
            
            117. 8 chocolates 7 equal weight = no of iterations to find the odd one(best and worst case)
            
            118. 25 horses problem
            
            119. elephant weight prblm.1kg = 2.2 pounds
            
            120. Http / Https 
            
            121. Can you write logic for palindrome (121)
            
            122. Fibonacci (1,1,2,3,5,8,13É.)
            
            123. Error vs Exception (java.lang.OutOfMemoryError, Java.lang.NoClassDefFoundError,
                 java.lang.UnSupportedClassVersionError  FileNotFoundException,ArrayIndexOutOfBoundsException , NullPointerException
            
            Error is not meant to catch .  Error are often fatal in nature and recovery from Error is not possible.
            
            124. Syntax for enum
            
            125. How can we stop UITableView to create new UITableViewCell on the time of scrolling?
            
            126. Explain about strong ,weak reference on board
            
            127. NSPointerArray *weakRefArray = [[NSPointerArray alloc] initWithOptions: NSPointerFunctionsWeakMemory];
            
            since NSArray normally stores a strong reference to its members.
            
            128. Steps to do memory profiling?
            
            129 [[NSOperationQueue mainQueue] addOperationWithBlock:^{         self.alert.text = @"Thanks!";     }];
            
            main thread to update UI related stuff
            
            130. What is the order of gcd operations and operation queues
            
            131. How to requests extra execution time ?  beginBackgroundTaskWithExpirationHandler
            
            http://stackoverflow.com/questions/11044095/ios-keep-an-app-running-like-a-service
            
            132. Interapp communication/ url schemas
            
https://developer.apple.com/library/ios/documentation/iPhone/Conceptual/iPhoneOSProgrammingGuide/BackgroundExecution/BackgroundExecution.html
            
            133. What method will be executed when local/remote notification hit your device for a specific app.
            
            https://developer.apple.com/library/ios/documentation/UIKit/Reference/UIApplication_Class/index.html
            
            134. Code for convert string to integer , vice versa
            
            135. if ([UIImagePickerController isSourceTypeAvailable: UIImagePickerControllerSourceTypeCamera])    // Has camera
            
             
            
            136. Data persistence and sand boxing http://code.tutsplus.com/tutorials/data-persistence-and-sandboxing-on-ios--mobile-14078
            
            Documents directory  = user data
            
            Library directory = app data (cache,preferences(.plist)
            
            Tmp directory =
            
             
            
            SQLite is portable = server less
            
            SQLite and Objective-C -Object Relational Mapping (ORM) solution is Core Data
            
            136. What is core data
            
            137. How do you try to solve one technical issue /bug
            
            138. What is signature of a method?
            
             
            
            ¥   Who (Certificates Ð has the app been signed with a private key, 
                which has a corresponding public key in the provisioning profile. The certificate also associates 
                the developer with a development team)
            
            ¥   What (Individual App ID Ð Does the Bundle Identifier set in your Info.plist match the App ID in the provisioning profile?)
            
            ¥   Where (Devices Ð Is the device contained in the provisioning profile?)
            
            https://developer.xamarin.com/guides/ios/getting_started/installation/device_provisioning/#Provisioning_for_Application_Services
            
             
            
            iCloud Storage, which includes three types of storage:
            
            ¥           key-value storage
            
            ¥           document storage
            
            ¥           Core Data storage.
            
            139. What is Lazy Loading
            
             
            
            140 life cycle of UIViewController : viewDidLoad, viewWillAppear, viewDidAppear, viewWillDisappear, viewDidDisappear
            
            141. life cycle of UIView : awakeFromNib, willMoveToSuperView, didMoveToSuperView
            
            http://chrisrisner.com/categories
            
            willRotateToInterfaceOrientation  UIInterfaceOrientationLandscapeLeft
            
            142. What do u know about app thinning?
            
            143. What is app store slicing operation? On downloading app from app store , 
            End users get just the resources needed for their device
            
            144. What is On-demand resources? additional content for the app on the iTunes App Store repository.
            
            145. What is Energy gauge for iOS ?
            
            146. Read-eval-print loop (REPL) in LLDB. Xcode 6
            
            147. View debugging ?
            
             148. iOS developers can now create dynamic frameworks from xcode 6
            
             
            
            https://developer.apple.com/library/ios/documentation/DeveloperTools/Conceptual/WhatsNewXcode/Articles/xcode_7_0.html
            
             
            
            149. iOS  Stack views
            
            150. What is beta period of test flight? 60 days
            
            151. Why size classes? Compact,regular ,any
            
            152. What green dot indicated in size class control
            
            153. What is an extension point? sharing, Notification Center, and the iOS keyboard
            
            an app that contains one or more extensions is called a containing app
            
            154. If you override a method using category , u canÕt call super method as we do in subclassing
            
             
            
            155. @dynamic
            
            #import <objc/runtime.h>
            
            void dynamicMethodIMP(id self, SEL _cmd) {
                // implementation ....
            }
            
            + (BOOL)resolveInstanceMethod:(SEL)aSEL
            
            {
                if (aSEL == @selector(resolveThisMethodDynamically)) {
                    class_addMethod([self class], aSEL, (IMP) dynamicMethodIMP, "v@:");
                    return YES;
                }
            
            156. about blocks
            
            void(^simpleBlock)(void)=^{
                //some print info
            };
            
            
            int anInteger = 42;
            void (^testBlock)(void) = ^{
                NSLog(@"Integer is: %i", anInteger);
            };
        
            anInteger = 84;
            testBlock();
            output is Integer is:42
            
            157.
            use __block Variables to Share Storage then out is 84
            
            158. -   (void)beginTaskWithCallbackBlock:(void (^)(void))callbackBlock;
            
             
            
            159. XYZBlockKeeper * __weak weakSelf = self;
            
             
            
            160. #define DebugLog(...) NSLog(__VA_ARGS__)
            
            161. What is the difference between storing data on the heap vs. on the stack?
            
            162. What is the number 21 in binary format? And in hex
            
            163. How do you find out if a number is a power of 2? And how do you know if it is an odd number?
            
            164. Can you name the different tiers and responsibilities in an N-tier architecture?
            
            165. Can you name any differences between object-oriented design and component-based design?
            
            166. What do you care about most when reviewing somebody elseÕs code?
            
            167. How can you changes already committed msg in git
            
            168. Tell me scope of one of ur project worked for
            
            169. We use AF networking / alamofire to encrypt http traffic from ios apps with TLS
            
            170. Test flight helps to avoids getting UDIDs from testers and creating provisioning profiles.
            
            171. Test flight used ad hock discribution to release beta releases to peers through test flight app.
            
            172. Provisioning profile will have all list of UDIDs and its missing connectivity bw certifcate and device.
            
            173. Transport Layer Security (TLS) and its predecessor, Secure Sockets Layer (SSL), 
            both frequently referred to as "SSL", are cryptographic protocols
            
            174. Communcation bw objective-c and swift files using brdige files
        
            
             
            
            Read more: http://java67.blogspot.com/2012/12/difference-between-error-vs-exception.html#ixzz3yZFdGB5N
            
             
            
            Search for 'iOS Interview Questions' in google and try to cover maximum
            
             
            
            ¥    Content view controllers manage a discrete piece of your appÕs content and are the main 
            type of view controller that you create.
            
            ¥    Container view controllers collect information from other view controllers (known as child view controllers) 
            and present it in a way that facilitates navigation or presents the content of those view controllers differently.
            
             
            
            Source websites :
            
             
            
            http://www.techotopia.com
            
            http://nshipster.com/
            
            https://developer.apple.com/library/ios/navigation/
            
            https://developer.apple.com/library/mac/documentation/Cocoa/Reference/ObjCRuntimeRef/index.html#//apple_ref/c/func/class_addMethod
            
            http://www.tutorialspoint.com/objective_c/index.htm
            
             
            
             
            
            Help
            
            https://www.interviewbit.com/
            
            http://www.programcreek.com/2012/11/top-10-algorithms-for-coding-interview/
            
             
            
            Learn Swift
            
            https://developer.apple.com/library/prerelease/ios/documentation/Swift/Conceptual/Swift_Programming_Language/TypeCasting.html#//apple_ref/doc/uid/TP40014097-CH22-ID338
        `}</pre>
    </div>
);

export default IosNotes;