import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
    align-self: center;
    align-items: center;
    margin: 15px;
`

const Container = styled.div`
    display: flex;
    flex-flow: column;
    `

const HeroImage = styled.div`
    width: 100%;
    height: 10px;
    background-color: #1fc8db;
    background-image: linear-gradient(140deg, #20ac07 0%, #FCFFAF 50%, #dc9a28 75%);
    color: white;
    opacity: 0.95;
    margin-bottom: 16px;
`

const portfolioPic = "https://lh3.googleusercontent.com/pw/AP1GczMKb_cXZFGVAG3TB-NRw8uT_z_pg5TJAMfn4Cy7bKAJhl9sO8I_jCnvKJcsci24nlBqQoYLGR8avn_2s9OIu8eE2DU960iRLTYiQawtbz15Z_2_PXQ"
const Home = () => (
    <Container>
        <HeroImage />
        <StyledDiv>
            <a href={portfolioPic+"=w2400"}>
              <img src={portfolioPic+"=w240"}  alt='A person in maroon t-shirt'/>
            </a>
        </StyledDiv>
        <h3>Hello there <span role='img' aria-label="Waveing hand emoji">👋🏻</span></h3>
        
        <p>My name is Uday. This is my personal website. Here you can find out <a href="#about">about me</a>. 
        I also use this space to share learning content, photography, videos etc. Professionally, I'm a software developer since 2011. 
        I am married and a father of two boys. I'm Currently living in Georgia, USA.</p>

        <p>Thanks for visiting my website! <span role='img' aria-label="Heart emoji">🧡</span></p>
    </Container>
);

export default Home;