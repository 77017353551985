import React from 'react';
import styles from 'styled-components'

const StyledTable = styles.table`
    border: 2px solid green;
`

const StyledTd = styles.td`
    border: 1px solid green;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
`

const StyledTr = styles.tr`
    border: 1px solid green;
`

const StyledTh = styles.th`
    border: 2px solid green;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
`
/* eslint-disable */
const WebNotes = () => (
    <div>
        <h4>Web technologies personal interview notes from year 2015</h4>
        <pre>{`
            Web Technologies Interview Questions

 

            HTML
            
            https://html.spec.whatwg.org/multipage/
            
            https://www.w3.org/TR/html51/
            
            1.     Why Doctype? to make browser understand which version of html we are using
            
            2.     Is HTML case sensitive? no
            
            3.     What is the purpose of lang attribute? for a11y for lang translations
            
            4.     How do u comment html line <!--  -->
            
            5.     Conditional comments
            
            <!--[if IE 8]>
                .... some HTML here ....
            <![endif]-->
            
            6.     How to open a link in new tab/window? target="_blank"
            
            7.     What is the importance of viewport meta tag?
            mobile browsers creates virtual view ports 
            (i.e if device width is 320px but still safari create virtual view port with width 980px 
                (just a standard to support many sites in 2007time. 800 in android). 
            so with viewport meta tag we tell to mobile browser to set virtual viewport width = device-width
            But browser creates real view port based on browser resize
            
            8.      initial-scale=1 seems to be necessary to make the virtual viewport adapt to landscape mode on iOS.
            
            9.     How to refresh a page for every 30sec? <meta http-equiv="refresh" content="30">
            
            10.  What is the importance of semantic elements in html5? mainly for a11y and developer readability
            
            11.  How to draw a circle using canvas?
            var c = document.getElementById("myCanvas");
            var ctx = c.getContext("2d");
            ctx.moveTo(0,0);
            ctx.lineTo(200,100);
            ctx.stroke();
            
            12.  SVG stands for?
            
            13.  Call event.preventDefault() to prevent the browser default handling of the data (default is open as link on drop). 
            prevent the default action 
            
            14.  event.stopPropagation()//Prevents further propagation of the current event in the capturing and bubbling phases.
            
            15.  Already cached local cache manifest gets updated only if new version of manifest served from server
            
            16.  HTML5 defines eight new semantic elements. All these are block-level elements. 
            (header, section, footer, aside, nav, main, article, figure
            
            17.   <blockquote cite="http://www.worldwildlife.org/who/index.html">
            For 50 years, WWF has been protecting the future 
            </blockquote>
            defines a section that is quoted from another source.
            
            18.  Strong vs bold, em vs i, for screen readers
            
            19.  <p>The <abbr title="World Health Organization">WHO</abbr> was founded in 1948.</p>
            
            20.  The <address> element is usually displayed in italic. Most browsers will add a line break before and after the element. 
            Android has feature to select whole on address text on long press. So this tag may help to OS to identify and doing so.
            
            21.  <p><cite>The Scream</cite> by Edvard Munch. Painted in 1893.</p>
            The HTML <cite> element defines the title of a work.
            Browsers usually display <cite> elements in italic.
            
            22.  <link rel='stylesheet' href="styles.css"> // rel must be there else style won't work
            
            23.  <a href="https://www.w3schools.com/html/">Visit our HTML tutorial</a>
            after html the forward slash is important else service will make two calls
            first one as it is and second one by adding slash bcz first one got failed. Here second one also failing. 
            But directly with slash is working
            
            24.  for img tag if width and height are not specified, the page will flicker while the image loads.
            
            25.  alt tag is for screen readers when image not found
            
            26.  <iframe src="demo_iframe.htm" name="iframe_a"></iframe>
            <p><a href="https://www.w3schools.com" target="iframe_a">W3Schools.com</a></p>
            
            27.  Create base url for all relative targets
            <base href="https://www.w3schools.com/images/" target="_blank">
            how to open all links in a page by default in new tab? use <base target="_blank"/>
            
            28.  non breaking space &nbsp; is an html entity ex use case 10&nbsp;AM. So whole word (10 AM) will move to next line
            
            29.  in HTML4 <meta http-equiv="Content-Type" content="text/html;charset=ISO-8859-1">
            in HTML5 <meta charset="utf-8">
            
            30.   
            
             
            
            CSS
            
            1.     Must know concepts:
            position, display, max-width, min-width, flex, float, clear, clearfix, overflow
            
            2.     How to align an element vertical center like div inside div
            
            3.     In how may ways we can style elements? Inline, Internal, External
            
            4.     units in px, vh, vw, % , ems
            
            5.     css flexbox in css3 is to accommodate page layout for diff screen sizes and diff display devices.
            
            6.     Element positions with respect to view port  (static, fixed, relative, absolute and inherit.
            https://codemyviews.com/blog/the-lowdown-on-absolute-vs-relative-positioning
            
            7.     height of any html element by default is wrap content
        `}</pre>
        <StyledTable border="1">
            <StyledTr><StyledTh>static (default)</StyledTh><StyledTh>relative</StyledTh><StyledTh>absolute</StyledTh><StyledTh>fixed</StyledTh><StyledTh>inherit</StyledTh></StyledTr>
            <StyledTr>
                <StyledTd>
                    
                        <p>1. the element is not positioned and occurs where it normally would in the document.</p>

                        <p>2. Static content floats with page.</p>
                
                </StyledTd>
                <StyledTd>
                        <p>1. can use top or bottom, and left or right to move the element relative to where it would normally occur in the document.</p>

 

                        <p>left property dominates right if both given</p>
                        
                        <p>like left:20px;right:40px;</p>
                        
                         
                        
                        <p>2. First browser render all elements then moves it.</p>
                        
                        <p>3. this propery not actualy for the elements own position but actually for its child position.</p>
                        
                        <p>When child is absolute instead of with respect to view port, to make absolute work with
                        
                        respect to parent.</p>
                
                </StyledTd>
                <StyledTd>
                <p>1. the element is removed from the document and placed exactly where you tell it to go. respect to view port</p>

 

                <p>     2. first element renders and get removed completely from its place as if its not there in the document.</p>
                        
                <p>     3. To make absolute work with respect to its parent element instead of view port, give parent element position as relative.</p>
                    
                </StyledTd>
                <StyledTd>
                    
                <p>     1.fixes the position with respect to view port.</p>

 

                <p>     2. Fixed content won't float with page.</p>
                    
                </StyledTd>
                <StyledTd>
                    
                <p>     inherits the value of its parent</p>
                    
                </StyledTd>
            </StyledTr>
        </StyledTable>
        <pre>{`
            :link pseudo-class to target all anchors tags which have yet to be clicked on.

            9.     css combinators
            A+B plus (B adjacent of A) plus + adj = pledge
            A B 'space' (B any level depth descendant of A) space+descend = speds
            A > B  greater than (B only one level depth of A i.e direct child) greater direct
            A ~ B like A +B but selects only one first adjacent
            
            10.  difference between the standard X Y and X > Y
            x y means (descendants to any depth) x > y (only direct children means one level depth)
            
            11.  X.class (x with class) X['name'] (x with attribute) no space mean with
            
            12.  X[href*="nettuts"] href link contains 'nettuts' start means anything
            
            13.  X[href^="http"] carat indicated begins with. all links begin with http (carat-start= charastar)
            
            14.  X[href$=".jpg"] dollar indicated end with. all links begin with http (dollar-end)
            
            15.  tilda (~) symbol allows us to target an attribute which has a spaced-separated list of values. need to understand it
            
            16.  ::after and ::before pseudo classes use full to append or prepend run time elements 
            like adding block level space element after element with class
            div.bigbox::after{
            content: "";
            
                  display: block;
            
                  clear: both;
            
                  visibility: hidden;
            
                  font-size: 0;
            
                  height: 0;
            
                  }
            
            17.  X:not(selector) // very useful negation
            
            18.  p::first-letter , li:nth-child(3), li:nth-last-child(2), p:only-child, p:last-child etc etc
            
            19.  for cross browser compat
            we should use css reset mechanism. Means reset all default element styles explicitly. 
            To restore default css values rather than browser default values.
            Include conditional css file in html with
            <!--[if IE]>
                    <link href="ie.css" rel="stylesheet" type="text/css" />
            <![endif]-->
            
            20.  inline elements can not accept width and height changes by css
            
            21.  css3 features: multi column layout, rounded corners, border image, box shadow, 
            text shadow, transform property, new pseudo classes
            
            22.  default fonts (serif, sans-serif, cursive, fantasy, and monsospace). Don't use fixed font size
            
            23.   
            
             
            
             
            
             
            
             
            
            JavaScript
            
            1.     What is promise object
            
            2.     Explain drag nd drop implementation
            drag element: draggable="true", ondragstart="dragFun(event)"
            drop element: ondrop="drop(event)", ondragover="allowDrop(event)"
            function dragFun (ev) {
                ev.dataTransfer.setData("text", ev.target.id);
            }
            function drop(ev) {
                ev.preventDefault();
                var data = ev.dataTransfer.getData("text");
                ev.target.appendChild(document.getElementById(data));
            }
            To allow a drop, we must prevent the default
            
            3.     What r call and apply methods and difference? apply take array as second arg
            
            4.     JS function args r pass by value or pass by reference?objects pass by ref, primitives pass by value
            
            5.     How to avoid not defined errors? use typeof operator
            
            6.     What is dynamic typing?
            
            7.     Diff bw === and == null===undefined and null==undefined
            
            8.     How to create readonly property of an object
            
            9.     How to check is array on an object?
            
            10.   Ò5Ó+2 = 52 , Ò5Ó-2 = 3
            
            11.  What are different regular expression modifiers and methods? /g,/i, /d
            
            12.  What is Òuse strictÓ
            
            13.  How to delete a variable? delete obj.prop2 (note: we can't delete variables)
            
            14.   JavaScript was invented by Brendan Eich in 1995, and became an ECMA standard in 1997.
            ECMA-262 is the official name. ECMAScript 6 (released in June 2015)
            
            15.   What happens if already initialized var declared again? 
            If you re-declare a JavaScript variable, it will not lose its value.
            
            16.   10 square 10**2, 5 cube 5**3 Exponentiation ES6
            
            17.   Data types : String, Number, Boolean, Array, Object, undefined, null, function.
            
            18.   When adding a number and a string, JavaScript will treat the number as a string. 14+"usk" = 14usk
            
            19.   typeof null = "object"
            typeof undefined = "undefined"
            
            
            20.   typeof [] = "object"
            typeof {} = "object"
            
            
            21.   Methods are stored in properties as function definitions. var obj = {prop:1, method: function(){}}
            
            22.   var x = "John";
            var y = new String("John");
            typeof x = "string";
            typeof y = "object" ;
            ( x === y) is false
            
            23.   slice(start, end) ; substring(start, end) ; substr(start, length)
            slice and substring are similar. The difference is that substring() cannot accept negative indexes.
            
            24.   str = "abc here and abc there";
            str.replace(/abc/g,"xyz")
            retruns new string as "xyz here and xyz there"
            
            25.   var text = "Hello" + " " + "World!";
            var text = "Hello".concat(" ","World!");
            both are same
            
            26.   Accessing a String as an Array is Unsafe.
            It does not work in all browsers (not in IE5, IE6, IE7)
            
            27.   var array = "abc".split('');
            array is ["a", "b", "c"]
            convert array to string. array.join('')
            
            28.   var a = 4; a.toString(2) = 100 //Binary conversion
            
            29.   typeof Infinity = "number" , typeof NaN = "number"
            
            30.   Does js supports associative arrays? Ans: no; Arrays with named indexes are 
            called associative arrays (or hashes). js supports only number indexs 0,1,2 etc
            
            31.   "use strict"; is to stop bad syntax . No global variable, no undeclared variables 
            like x=5, can't assign value to non writable property, no duplicate arguments,
            
            32.   var obj = {};
            Object.defineProperty(obj, "pi", {value:3.14, writable:false}); 
            //obj.x prints 3.14 and can change it by obj.x = 33;
            
            33.   array.splice(fromIndex to insert new elements, no of elements to remove, new element1, new element2 )
            
            34.   array.slice( slice fromIndex, no of elements to slice)
            
            35.   In JavaScript there are
            5 different data types that can contain values:
                        string, number, boolean, object, function
            3 types of objects
                        Object, Date, Array
            2 data types that can not contain values
                        null, undefined
            
            36.   regular express method test() returns true/false ex:  /e/.test('hyderabad') o/p true
            regular express method exec() returns value found ex: /ad/.exec('bad sad') o/p ['ad']
            
            37.   hoisting : moving declarations to top but initializations
            
            38.   switch case does strict comparison i.e ===
            
            39.   null is for objects, undefined is for variables, properties, and methods
            
            40.   use defer="true" in the script tag. script should be executed 
            after the page has finished parsing, but it only works for external scripts. what is async attribute?
            
            41.   Objects are mutable: They are addressed by reference, not by value. But js variables are immutables.
            
            42.   Methods are functions stored as object properties.
            
            43.   prototype is an object. Every JavaScript object has a prototype.
            
            44.   Person.location  location is prototype property of Person constructor.
            p = new Person(); p.name  name is direct property of Person's object called p
            
            45.   In inheritance , parent class constructor properties (i.e this.name ,this.age etc ) 
            will become direct properties of child.
            parent class prototype properties (i.e p is a function then p.prototype.location = india) 
            will become direct properties of child's __proto__
            funA.prototype.proto1 = 'abc'
            fun1 = new funA(); then fun1.__proto__ will have proto1 property
            
            46.   __proto__ holds parent class object.
            
            47.   If u r going to use a function as a class then use function name as Person, Vehicle etc ,
             else use name as normal function like showButton, getPersonDetails etc
            
            48.   Function expression -> var abc = function (a, b) {return a * b};
            
            49.   Function declaration -> function myFunction(a, b) {return a*b}
            
            50.   Create function using constructor -> var myFun = new Function("a", "b", "console.log(a);return a * b");
            
            51.   Function hoisting ->JavaScript functions can be called before they are declared:
            myFunction(5);
            function myFunction(y) {
                return y * y;
            }
            but Functions defined using an expression are not hoisted
            myFun(5);
            var myFun = function(y) {
                return y * y;
            }
            
            
            52.   You can self invoke only function expression not function definition 
            (IIFC immediate invoke function calls)
            (function(){console.log('hi');})() //works
            (function abc(){console.log('hi');})() // works
            
            53.   func1 = function(param1, param2){}; var arg1 =30, arg2 = 40; func1(arg1,arg2);
            parameters are names listed in funciton definition . arguments are real values passed to function.
            
            54.   func1.call( ) takes list of arguments but apply array of arguments
            
            55.   apply takes array as second argument , call takes function arguments separately. 
            First argument is context i.e owner object. in strict mode owner object can be anything. 
            But in non strict mode if owner obj is null or undefined then it will be replaced with global object .
            
            56.   Private variables can be made possible with closures.
            A closure is a function having access to the parent scope, even after the parent function has closed.
            var Counter = (function(){
                        var c = 0;
                        return function(){ return c+=1;};
            })();
            Counter(); // out put 1
            Counter(); // out put 2
            Counter(); // out put 3
            Note: c is called a closure variable . c is a private variable.
            
            57.   "The W3C Document Object Model (DOM) is a platform and language-neutral interface 
            that allows programs and scripts to dynamically access and update 
            the content, structure, and style of a document."
            
            58.   DOM is interface to delete, get, modify, add elements of a document
            
            59.   document.referrer : returns the previous url i.e from where current page opened
            
            60.   document.querySelectorAll("p.intro");
            
            61.   <div class="portal" role="navigation" id="p-support" aria-labelledby="p-support-label">
            document.querySelectorAll('div.portal#p-support[role="navigation"]');
            
            62.   var timerId = setInterval(func, intervalTimeInMS); clearInterval(timerId);
            
            63.   element.addEventListener(event, function, useCapture);
            can add multiple call back funtions to same event using addEventListener
            for ex onclick event = 'click'
            
            64.   window.document is know as HTML DOM . Here window is BOM
            
            65.   var timeout  = setTimeout(func, execAfterMilliSeconds), clearTimeout(timeout);
            
            66.  var myFun = { func: function(){ console.log('hi'); }} is called regular (non-constructible) object
            
            67.   what is first class function -> function which can be passed as an argument/ variable .
            
            68.   Object.assign ( target, ...sources )
            
            69.   Object.create ( O, Properties )
            
            70.   Regex
            bo* - o can be zero times OR can more no of times  
            1. /bo*/.exec('aab') = ["b"] // o zero times
            2. /bo*/.exec('aabo') = ["bo"]   // o one time
            3. /bo*/.exec('aaboooc') = ["booo"] // o more than one time
            4. /bo*/.exec('aabocbobo')  =  ["bo"] // finds only once
            5. /bo*/.exec('aabcbobo')  =  ["b"] // finds only once
            
            bo+ - o should be one time OR more no of times 
            1. /bo+/.exec('aab') = null  // o is zero times
            2. /bo+/.exec('aabo') = ["bo"]   // o one time
            3. /bo+/.exec('aaboooc') = ["booo"] // o more than one time
            4. /bo+/.exec('aabocbobo')  =  ["bo"] // finds only once
            5. /bo+/.exec('aabcbobo')  =  ["bo"] // finds only once
            
            bo? - o can be zero times OR only one time
            1. /bo?/.exec('aab') = ["b"]// o is zero times
            2. /bo?/.exec('aabo') = ["bo"]   // o one time
            3. /bo?/.exec('aaboooc') = ["bo"]// o more than one time
            4. /bo?/.exec('aabocbobo')  =  ["bo"] // finds only once
            5. /bo?/.exec('aabcbobo')  =  ["b"] // finds only once
            
            /.n/ - finds n along with one character preceding to n
            /.n/.exec("nay, an apple is on the tree") = ["an"]
            /.n/.exec("nay, ann apple is on the tree") = ["an"]
            /.n/.exec("nay,aann apple is on the tree") = ["an"]
            /.n/.exec("nay,nn apple is on the tree") = [",n"]
            /.n/.exec("nay, apple is on the tree") = ["on"]
            
            
            71.   weakmap weakset are to stop memory leak
            
            72.   obj = {
            
                  myname: "usk",
            
                  printmyname: function(){
            
                  console.log(this.myname);
            
                  }}
            
            73.   The declarative, delegated jQuery events means that you donÕt have to worry about 
            whether a particular element has been rendered to the DOM yet or not.
            
            74.   A hash sign (#) in a URL is referred to as a fragment 
            
            75.   window.pageYOffset to identify how much user scrolled
            
            76.   document.documentElement is nothing but html element. he root element of any HTML document
            
            77.   document.documentElement.clientWidth is view port width
            
            78.   root element(<html>) width can be different than view port width. But mostly no one does)
            
            79.   screen.width(monitor display width) contains window.outerWidth (browser window width)
            
            80.   window.outerWidth contains window.innerWidth ( width of available space to render page including scroll bars
            
            81.   window.innerWidth contains document.documentElement.clientWidth 
            (width of available space to render page excluding scroll bars
            
            82.   document.documentElement.clientWidth (i.e view port) contains 
            document.documentElement.offsetWidth (i.e root element (<html>))
            
            83.   document.documentElement.offsetWidth gives the actuall root element (<html>) width
            
            84.   scrollbar = innerWidth - document.documentElement.clientWidth
            
            85.   from an event e.pageX / Y gives the coordinates relative to the <html> element in css pixels
            
            86.   e.clientX/Y gives the coordinates relative to the viewport in css pixels
            
            87.   screenX/Y gives the coordinates relative to the screen in device pixels
            
            88.   width proper in css is relevant to documentElement.clientWidth (i.e view port width) in css pixels
            
            89.   device-width is actual screen width of monitor / device (we don't use it) in device pixels
            
            90.   so in view port meta tag we give width = device-width this is for 
            mobile/tablets because you can't resize browser in devices. 
            So telling browser to use view port width equal to device width
            
            91.  How JSONStream works and what is work around solution?
            https://github.com/dominictarr/JSONStream
            https://stackoverflow.com/questions/11874096/parse-large-json-file-in-nodejs
            
            92.  
            
            
            ES6
            
            1.     An important difference between function declarations and class declarations 
            is that function declarations are hoisted and class declarations are not
            
            2.     Since this is not bound in arrow functions, the call() or apply() methods can only pass in arguments; this is ignored:
            
            3.      ArrowFunction and does not have a local this value.
            
            4.     Array extras . array map, reduce
            
            Features
            
            1.     What are diff ways to store client side data
            
            2.     What is application cache?
            
            3.     What is a web worker? How to update UI using web worker?
            
            4.     What is promise object?
            
            5.     What do you know about server sent events?
            
            6.     A Progressive Web App (PWA) is a web app that uses modern web capabilities 
            to deliver an app-like experience to users. ... 
            You can deploy your app as a PWA as well as Native app and take advantage of both channels. 
            Ionic allows you to ship your app to not only the app store, but also deploy to the mobile web as a PWA.
            
            7.     progressive means build an app to support most browsers and wide form factors
            
            8.     Shadow DOM: for scoped css and
            
            9.     closed shadow DOM: component user can not access inner html of component
            const div = document.createElement('div');
            const shadowRoot = div.attachShadow({mode: 'closed'}); // close shadow tree
            
            10.  Light DOM: The markup a user of your component writes
            
            11.  Shadow DOM: The DOM a component author writes
            
            12.  Flattened DOM tree: The result of the browser distributing the user's light 
            DOM into your shadow DOM, rendering the final product.
            
            13.  The <slot> element : Slots are placeholders inside your component that users can fill with their own markup.
            
            14.  Service Workers: caching shell files, handle spotty networks, to create offline first web apps.
            
            15.  Use the Network Throttle dropdown to set the network speed to Regular 2G
            
            16.   
            
            TOOLS
            
            1.     How do you debug ?How do you find the first non lib method called for click interaction using chrome tools
            
            2.     How do run ur localhost app in ur mobile
            
            3.     How to skip debug step in to lib files
            
             
            
            ARCHITECHTURE
            
            1.     What is the diff bw responsive and adaptive
            
            2.     What is the diff bw single page and multi page app
            
             
            
            WEB SERVICES
            
            1.     Diff bw json and xml
            
            2.     Diff bw get and post
            
            3.     What is RESTful web service
            
            4.     What is CORS issue
            
            5.     How do you pass special characters in query params
            
            6.     HTTP vs HTTPS differences, protocols, Content-Type
            
            7.     HTTP has been defined to communicate information between two pieces of software via HTTP messages. 
            So HTTP is an application protocol that sits above the communication protocol.
            
            8.      
            
            9.      
            
             
            
            GENERAL
            
            1.     What is PI
            
            2.     What is aspect ratio
            
            3.     AB : A leading in AB
            
            4.     what is RSS feed? How it works?
            
            5.     Read All basic maths
            
            BACKBONE (https://addyosmani.com/backbone-fundamentals/)
            
            1.     Router decides view based on url
            
            2.     listenTo vs on? listenTo is good
            
            3.     passing data bw routes
            
            4.     history start used for what?
            Backbone.history.start(); // Start listening to the routes and manages the history for bookmarkable URL's
            
            5.     what extends exactly does?
            
            6.     how modal works for web services ? fetch, sync, etc?
            
            7.     What are building blocks of backbone? modal, view and router
            
            8.     Disadvantages of backbone ? boilerplate code and hard dependency
            
            9.     backbone routes with :params, *splat, :a/:b/:c (https://cdnjs.com/libraries/backbone.js/tutorials/what-is-a-router)
            
            10.  How to remove # from backbone routing urls?
            
            11.  What backbone history bookmarking means?          
            
            12.  The default implementation of View.remove() makes a call to stopListening(), 
            ensuring that any listeners bound using listenTo() are unbound before the view is destroyed.
            But on() won't work like this. So memory leak happens. becaues off() not getting called by default when view getting destroyed.
            
            13.  listenTo is to listen to event on some other object. a.listenTo(b, 'eventInB', fucntion(){//callback in a object})
            but on() listens to same object events. a.on('evenInA', function(){//callback in a});
            here a = mixin of Backbone.Events i.e a ={}; _.extend(a, Backbone.Events);
            
            14.  view.remove(); // stopListening() implicitly called
            
            15.  delegateEvents(): attaches events to dom element after attaching el to DOM tree. 
            When backbone view got remove by calling view.remove() then all events (i.e events:{ 'click #btn':'callback'}) 
            of a view got detached from DOM. 
            So when we attache the same view again then we must call delegateEvents() to add events to live dom.
            
            16.  Backbone offers an opt-in for HTML5 pushState support via window.history.pushState. 
            This permits you to define routes such as http://backbonejs.org/just/an/example. Means just to stop showing hash(#) in urls
            just change the url with out reloading the page by using pushstate.
            
            17.  Backbone is not a complete framework, itÕs a library
            
            18.   
            
            
            ANGULAR
            
                1. angular js extends html
    
                2. u can directly use html as template language
    
                3. can write angular expressions directly inside html but js expressions can't be
    
                4. Angular js modules reduces the problem of global functions, by keeping all functions local to the module
    
                5. In AngularJS, $scope is the application object (the owner of application variables and functions).
    
                6. ng-model="firstName"  = {{ firstName }} == $scope.firstName
    
                7. ng-options vs ng-repeat
    
                    ng-repeat : it just repeats the inner elements. When we use it on select element. 
                    The selected value is string. but by using ng-options if we create select dropdown then selected value can be object also.
    
                8. should be careful about variable names ambiguity problem
    
                <select ng-model="selectedCar">
                            <option ng-repeat="x in cars" value="{{x.model}}">{{x.model}}</option>
                </select>
    
                <select ng-model="selectedCar" ng-options="x.model for x in cars">
                </select>
    
                9. $scope.$watch('passw1',function() {$scope.test();}); //watch passw1 variable of $scope
            
            Frameworks
            
                Backbone
    
                Angular
    
                Require
    
                Underscore
                
                _.templateSettings = { interpolate : /\{\{(.+?)\}\}/g }; //to change <%%> to {{}}
    
                JQuery
            
            Version Control
            
                Git
    
                    Taking a specific commit from a branch and applying on other branch . Ans : cherrypick
    
                Svn
            
            Web Services
            
                RESTful
            
            Database
            
                MySql
    
                Mongodb
            
            Platform
            
                Browser
    
                Node
            
            Architecture
            
                MVC
    
                Orchestration
            
            Design Patterns
            
            Data Structures
            
            ReactJS
            
            ES6
            
            Test Cases
            
            Deployment
            
            
            ???????
            
            1. Everything about this keyword
            
            2. __proto__ and prototype
            
             
            
            Read Analyze Understand AdaptiveUI & ui-components completely
            
            How to write a plug in for a browser - check videos
            
            what is the importance of a closing tag? Name few closing and no closing tags?
            
            What is character encoding utf-8 utf-16 etc
            
             
            
            Threads have a lot of benefits over the Processes such as they share a common memory area.
            
            servlet creates thread for each request
            
            CGI creates process for each request. No of requests increases responding time also increases.
            
            Library is reusable components
            
            Framework is for whole app up and running
            
            1. Specialization & Composition
            
            2. Enumerable & non-Enumerable
            
            3. Method & Function
            
            4. Prototype & __proto__
            
            5. Parameter & Argument
            
            6. hasOwnProperty
            
            7. Object.getPrototypeOf(obj_name)
            
            8. obj_name.propertyIsEnumerable(obj_property_value_name)
            
            9. Primitive values.
            
             
            
            JavaScript passes objects by reference, not by value
            
            JavaScript, null is for objects, undefined is for variables, properties, and methods.
            
             
            
            git merge creates a new commit with merge message 
            
            Use Lower Case File Names
            
            Patterns of input elements , regular expressions
            
            10&nbspAM . (because if line break after 10, makes no sense)
            
            CSS:
            
            CSS flexbox
            
            CSS Float and Clear
            
            Internet Explorer 8 and earlier versions, include padding and border in the width property. 
            To fix this problem, add a <!DOCTYPE html> to the HTML page.
            
            Outline Color - invert
            
             
            
            Explore
            
            MIDI files do not contain sound, but digital notes that can be played by electronics.
            
            What is bandwidth?
            
            What is frequency?
            
            compilers (translators that generate machine code from source code)
            
            interpreters (step-by-step executors of source code, where no pre-runtime translation takes place)
            
            application programming (for users)system programming (for computers)
            Medium-level language
             
            
            IMPORTANT CONCEPTS TO READ
            
            1.     Promise Objects
            
            2.     Regex patterns
            
            3.     Prototype
            
            4.     Ajax
            
             
            
            KNOW ATLEAST:
            
            1.     DataView
            
            2.     Proxy Object
            
            3.     WeakMap, WeakSet
            
            4.     Reflect Object
            
            5.     EventSource
            
            6.     DevOps
            
            7.     read about search engines optimizations
            
            8.     Pythagoras principle , Einstein
            
            9.     javascript:void(0) wt the hell is this 
            
            10.  Socket programming
            
            11.  Server side rendering
            
            12.  What is xhr request vs http request? XmlHTTPRequest is called xhr request?
            
            13.  Should be able to explain JS inheritance with code snippet
            
            14.  Learn more about react redux library for flex pattern why how
            
            15.  Jenkins and CDCI/ TeamCity / Bamboo
            
             
            
             
            
            1.    hoisting does not apply to scripts that are run in the Strict Mode.
            
            2.    Lambda functions are anonymous function and also called arrow functions.
            
            3.    var fun = function(){ console.log('this is function expression'};
            
            4.    function fun(){console.log('this is function declaration'};
            
            5.    line has equals symbol means expression
            
            6.    only declarations will be hoisted not expressions.
            
            7.    The difference between assign() and replace() method is that the location.replace() method deletes 
            the current URL from the document history, 
            so it is unable to navigate back to the original document. You can't use the browsers "Back" button in this case.
            
            8.     Object.create() method allows you to create the prototype for the object you want, 
            without having to define a constructor function.
            
            9.     Object de-structuring to variable will take only pass by value not reference.
            
            10.  The ES5 filter() and the ES6 find() are not synonymous. Filter always returns an array 
            of matches (and will return multiple matches), 
            find always returns the first found actual element.
            
            11.  Promises help you for cleaner code on multiple callbacks case
            
            12.  ES6 covering important features of underscore.js and require.js
            
            13.  href = "#" -> The # (hash mark) disables the link so that the browser does not
             try to go to a URL when clicked. This link is an image.
            
            14.  The void operator specifies an expression to be evaluated without returning a value
            
            15.  When using an immediately-invoked function expression, 
            void can be used to force the function keyword to be treated as an expression instead of a declaration.
            
            16.  javascript:some code to evaluate ex: javascript:alert('hi')
            
            javascript:void(some code to evaluate but not to do anything like return)
            The reason youÕd want to do this with the href of a link is that normally, 
            a javascript: URL will redirect the browser to a plain text version of the result of evaluating that JavaScript. 
            But if the result is undefined, then the browser stays on the same page. void(0) 
            is just the smallest script possible that evaluates as undefined.
            
            17.   
            
            18.  web app manifest: to control orientation, home screen icons,
            
            
            
            Accessibility
            
            1. role
            
            2. aria-live
            
            3. We can set focus programatically to an non focusable element only by setting tabindex -1 attribute to that element.
                 
            
            Interpreter _ Converts high level programs into machine level program line by line.
            cOmpiler _ Converts high level programs into machine level programs at one go rather than line by line.
            no power - no erase = non volatile
            
             
            
            What is prototype in javascript? The concept of prototyping implies that behavior can be 
            reused by cloning existing objects that serve as prototypes.
            
            * Inheritance in JS means, prototype of child is copy of parent prototype.
            
            var inheritsFrom = function (child, parent) {
            
                child.prototype = Object.create(parent.prototype);
            
            };
            
             
            
            * new ClassA().studentName; // works
            
            * for-in sees all properties of an object, including inherited ones i.e prototype props
            
             
            
            ClassA.age // this is own property
            
            ClassA.prototype.weight //this is inherited property
            
            Check own property using ClassA.hasOwnProperty('age') //returns true
            
             
            
            var a = {age:34};
            
            var b = Object.create(a, {weight: {value: 45}});
            
            b.weight // 45
            
            b.__proto__.age // 34
            
             
            
            * we can't change private variable value even by using prototype setters like below
            
            fun1 = function(){var age = 32; this.getAge = function(){return age;}}
            
            fun1.prototype.setAge = function(a){this.age = a}
            
            f1.setAge(44)
            
            f1.getAge() // out put is 32
            
             
            
            * In prototype system, "inheriting" objects do not have functionality copied over to them, 
            instead the functionality they inherit is linked to via the prototype chain.
            
            Parent functions are not copied to child, they are linked to child through prototype chain.
            
            * A "constructor" in JavaScript is "just" a function that happens to be called with the new operator.
            
             
            
            In setTimeout the second arg indicates a minimum time and not a guaranteed time. 
            Bcz if any other messages are in Queue then the timeout call back function execution may get delayed.
            
            
            isEven = function (n) {
            
                  return ((n>>>0).toString(2) & 1) == 0;
            
            }
           
            isFloat = function (n) {
            
                  return n%1!==0;
            
            }
            
            isFloat = function (n) {
            
                  return n === +n && n !== (n|0);
            
            }
            
            * How to check input n is number or not with out using isNan() function?
            
            n === +n  is to check numeric. if n is string then +n return NaN
            
             
            links
             
            https://excess-xss.com/
            
            https://codelabs.developers.google.com/
        `}</pre>
        <h4>JAVA vs NodeJS</h4>
        <StyledTable>
            <StyledTr><StyledTh>Java Application</StyledTh><StyledTh>Node Application</StyledTh></StyledTr>
            <StyledTr>
                <StyledTd>
                    <p>1.     Multi thread : means more time in switching context</p>

                    <p>2.     synchronous (blocking) model.</p>

                    <p>3.     Good for CPU-bound app. means which requires more processing/ calculations at server side. More computational processing.</p>

                    <p>4.     Front end and back end with different language</p>

                    <p>5.     No default support of JSON parse</p>
                </StyledTd>
                <StyledTd>
                    <p>Single thread. Just event loop with call back functions</p>
                    <p>asynchronous (non-blocking) model</p>
                    <p>Good for I/O-bound applications. Means just serving input /output data. Like JSON response for save and retrieve guest info. Just infromation exchage.</p>
                    <p>Javascript everywhere</p>
                    <p>Support of JSON parse by default</p>
                    <p>Development speed is more</p>
                </StyledTd>
            </StyledTr>
        </StyledTable>
        
        <h4>MongoDB vs RelationalDB</h4>
        <StyledTable>
            <StyledTr><StyledTh>Relational DB</StyledTh><StyledTh>Mongo DB</StyledTh></StyledTr>
            <StyledTr>
                <StyledTd>
                    
                </StyledTd>
                <StyledTd>
                    <p>document-oriented database designed for ease of development and scaling</p>
                    <p>ability to store JavaScript objects natively saves time and processing power</p>
                </StyledTd>
            </StyledTr>
        </StyledTable>
    </div>
);

export default WebNotes;